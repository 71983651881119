import { Row, Col, Image, Carousel } from 'antd'

const Rule = () => {
  return (
    <div style={{ backgroundColor: '#0C003D', color: 'white', fontSize: '16px' }}>
      <Row align="middle" style={{ height: '90vh' }}>
        <Col align="middle" textAlign="center" span={24}>
          <Carousel autoplay>
            <div>
              <Image style={{ maxWidth: '90%'}}
                src="images/r1.png"
                preview={false}
              />
            </div>
            <div>
              <Image style={{ maxWidth: '90%'}}
                src="images/r2.png"
                preview={false}
              />
            </div>
            <div>
              <Image style={{ maxWidth: '90%'}}
                src="images/r3.png"
                preview={false}
              />
            </div>
            <div>
              <Image style={{ maxWidth: '90%'}}
                src="images/r4.png"
                preview={false}
              />
            </div>
          </Carousel>
        </Col>
      </Row>
    </div>
  )
};

export default Rule;
