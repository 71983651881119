import React, { Component, useState } from 'react'
import { Link } from "react-router-dom";
import { Breadcrumb, Layout, Menu, MenuProps, theme, Col, Row } from 'antd';

const { Header, Content, Footer } = Layout;
const items = [
    {
        label: 'Home',
        key: '',
    },
    {
        label: 'Rule',
        key: 'rule',
    },
    // {
    //     label: 'Event',
    //     key: 'event',
    // }
]

class MyHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }


    render() {
        const onClickMenu = (e) => {
            console.log('click ', e);
            window.location.href = '/#/' + e.key
        };

        return (
            <Header style={{ backgroundColor: '#580189' }}>
                <Row align="middle">
                    <Col span={6} align="middle">
                        <div className="logo">
                            <img className="img-fluid" style={{ maxWidth: "90%" }} src="images/logo.png" alt="" />
                        </div>
                    </Col>
                    <Col span={14} class={{ textAlign: 'center' }} >
                        <Row align="right">
                            {/* <Col span={24} class={{ textAlign: 'center' }} align="right"> */}
                                <Menu onClick={onClickMenu} mode="horizontal" items={items} style={{ backgroundColor: '#580189', color: '#fff' }} />
                            {/* </Col> */}
                        </Row>
                    </Col>
                </Row>
            </Header >
        )
    }
}

export default MyHeader;
