import React, { Component } from 'react'
// import { Link } from "react-router-dom";
import { Form, Input, Col, Row, Button } from 'antd';

class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        const onFinish = (values) => {
            console.log('Success:', values);
        };

        const onFinishFailed = (errorInfo) => {
            console.log('Failed:', errorInfo);
        };

        return (
            <Row align="middle" style={{ backgroundColor: '#05001A', padding: '10px', color: 'white', fontSize: '16px' }}>
                <Col span={6} align="middle">
                    <div className="logo">
                        <img className="img-fluid" style={{ maxWidth: "90%" }} src="images/logo.png" alt="" />
                    </div>
                </Col>

                <Col span={18} align="right">
                    <Col span={24} style={{ padding: '0px 120px 4px 0px' }}>Get our lastest updates</Col>
                    <Form
                        name="basic"
                        layout="inline"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        style={{float: 'right'}}
                    >
                        <Form.Item
                            name="email"
                            rules={[{ required: true, message: 'Please input your email!' }]}
                        >
                            <Input placeholder="Please input your email" />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Send
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        )
    }
}

export default Footer;
