import Layout from "./Layout";
import { Row, Col, Image, Button } from 'antd'

const Home = () => {
  return (
    <div style={{ margin: '0, 20px', backgroundColor: '#000', color: 'white', fontSize: '16px' }}>
      <Row style={{ backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundImage: `url("images/map500.png")` }}>
        <Row style={{ marginTop: '30px' }} align="middle">
          <Col xs={24} xl={16} align="middle">
            <img src='images/MagicPuzzle.png' style={{ maxWidth: '100%' }}></img>
            <Row style={{ color: 'white', fontSize: '16px' }}>
              <div style={{ margin: '30px 0', padding: '0 15%' }} >Take an adventure to the magic world... <br />
                The levels are easy to pick up, but hard to put down! So get ready to have fun and enter the magic world!</div>
            </Row>
            <Row align="center" style={{ margin: '20% 0', padding: '0 20px', color: 'white', fontSize: '16px' }}>
              {/* <Col align="center" span={12} style={{ padding: '5px' }}>
                <a href="https://play.google.com/store/apps/details?id=com.onegame.magicpuzzle" target="_blank"><img src="images/btnapppl.png" style={{ maxWidth: "100%" }} /></a>
              </Col> */}
              <Col align="center" span={12} style={{ padding: '5px' }}>
                <a href="apk/MagicPuzzle.apk" download><img src="images/btnapk.png" style={{ maxWidth: "100%" }} /></a>
              </Col>
            </Row>
          </Col>
          <Col xs={24} xl={8} align="middle">
            <img src='images/1.png' width="100%" height="auto"></img>
          </Col>
        </Row>
      </Row>

      <Row style={{ padding: '50px 0', backgroundColor: '#0C003D' }} align="middle">
        <Col xs={24} xl={10} align="middle">
          <img src='images/2.png' style={{ maxWidth: '100%', height: 'auto' }} />
        </Col>
        <Col xs={24} xl={14} align="left">
          <Row>
            <Col span={24} style={{ fontSize: '300%' }}>WHAT IS </Col>
            <Col span={24} style={{ fontSize: '300%', color: '#39C7FE' }}>MAGIC PUZZLE ?</Col>
          </Row>
          <Row style={{ padding: '50px 2%', textAlign: 'left' }}>
            <div>A little witch with her pet in Magic World where an epic adventure full of challenges awaits the bold and the brave.
              <br></br>Switch and match your way through hundreds of levels in this divine puzzle adventure. </div>
          </Row>
        </Col>
      </Row>

      <Row style={{ padding: '0px 0', backgroundColor: '#0C003D' }} align="middle">
        <Col xs={24} xl={12} align="right" style={{ padding: '50px 0' }}>
          <Row>
            <Col span={24} style={{ fontSize: '300%', padding: '0 30px' }}>Obstacles abound </Col>
            <Col span={24} style={{ fontSize: '300%', padding: '0 30px', color: '#F6F95D' }}>on the journey</Col>
          </Row>
          <Row style={{ padding: '50px 2%', textAlign: 'right' }}>
            <Col span={24} style={{padding: '0 30px'}}>Ready your wand and help her defeat obstacle in the way! Take some favors from the owl and a old sorcerer as they will give you magical tricks. </Col>
          </Row>
        </Col>
        <Col xs={24} xl={12} align="right" style={{ padding: '50px 0' }}>
          <img src='images/3.png' style={{ maxWidth: '95%', height: 'auto' }} />
        </Col>
      </Row>
    </div>
  )
};

export default Home;
